@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  padding-top: $gap-6;

  @media (max-width: $screen-width-840) {
    padding: $gap-4;
  }

  .infoContainer {
    width: 480px;
    max-width: 648px;
    height: 346px;
    padding-bottom: 88px;

    .heading {
      padding:0;
      font-family: $font-family-display-font;
    }

    .subHeading {
      padding-top: 12px;
      padding-bottom: 8px;
    }

    @media (max-width: $screen-width-840) {
      width: auto;
    }
  }

  .illustration img {
    width: 360px;
    height: 360px;

    @media (max-width: $screen-width-840) {
      width: 160px;
      height: 160px;
    }
  }
}
