@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.stepContainer {
  display: flex;
  width: 100%;
  max-width: 40.5rem;
  height: auto;
  height: 29rem;
  flex-direction: column;
  padding: $gap-4;
  margin-top: $gap-6;
  gap: $gap-6;

  .heading {
    padding: 0;
    font-family: $font-family-display-font;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
    gap: $gap-3;

    .termsCheckboxContainer {
      margin-top: $gap-2;

      label {
        width: 100%;
      }
    }

    .sectionTitle {
      margin: $gap-3 $gap-0;
      font-weight: $font-weight-700;
    }
  }
}
