@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.firstLastNameStepContainer {
  width: 40.5rem;
  max-width: 100%;
  padding-top: $gap-6;

  @media (max-width: $screen-width-600) {
    padding: $gap-4;
  }

  .container {
    display: grid;
    padding: $gap-5 0;
    gap: $gap-5;

    @media (max-width: $screen-width-600) {
      padding: 0;
    }
  }
}

.heading {
  padding: 0;
  font-family: $font-family-display-font;
}
